import { logger } from './logging'
import { getBasePath } from './sitemap/base'

interface formatCanonicalParams {
  locale: string
  path: string
}

export function formatCanonicalUrl({ locale, path }: formatCanonicalParams): string {
  const base = getBasePath(locale)

  const isCanonical = /^.+:\/\/.*$/.test(path)
  if (isCanonical) {
    logger().info('formatCanonicalUrl: This path is already a fully qualified url.', { base, locale, path })
    return path
  }

  const separator = !path || path.startsWith('/') ? '' : '/'
  return `${base}${separator}${path}`
}

export function formatYouTubeUrl(url: string): string {
  return url.includes('youtube') && !url.includes('youtube-nocookie') ? url.replace('youtube', 'youtube-nocookie') : url
}
